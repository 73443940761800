import { getIdsFromArray } from '~/features/useUrlSearch';
import { removeNullProperties } from '~/features/useUtilities';

import { HTTP } from './HTTP.js';

export class OrganisationStatisticsService {
  constructor(organisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisation/${organisationId}/charts`;
    this.baseKpiUrl = `/api/customer/organisation/${organisationId}/kpis`;
  }

  filtersMeta(lastFourYears = false) {
    const path = `${this.baseUrl}/shipment_filters${
      lastFourYears ? '?type=4y' : ''
    }`;

    return HTTP.make(path, 'get');
  }

  shipmentRevenueTons() {
    const path = `${this.baseUrl}/shipment_volume`;
    const getFormattedQuery = ({
      period,
      tradeLanes,
      loadingPorts,
      dischargePorts,
      commodities,
      page,
      perPage,
    }) =>
      removeNullProperties({
        period: period.id,
        cp_start: period.cp_start,
        cp_end: period.cp_end,
        commodity: getIdsFromArray(commodities),
        trade: getIdsFromArray(tradeLanes),
        loading_port: getIdsFromArray(loadingPorts),
        discharge_port: getIdsFromArray(dischargePorts),
        per_page: perPage,
        page,
      });

    return {
      get: (data) => {
        return HTTP.make(path, 'get').data(getFormattedQuery(data));
      },
      download: (data) => {
        return HTTP.make(`${path}/download`, 'get').data(
          getFormattedQuery(data),
        );
      },
    };
  }

  laycanPerformance() {
    const path = `${this.baseUrl}/shipment_laycan`;

    const getFormattedQuery = ({
      period,
      tradeLanes,
      loadingPorts,
      page,
      perPage,
    }) =>
      removeNullProperties({
        period: period.id,
        cp_start: period.cp_start,
        cp_end: period.cp_end,
        trade: getIdsFromArray(tradeLanes),
        loading_port: getIdsFromArray(loadingPorts),
        per_page: perPage,
        page,
      });

    return {
      get: (data) => {
        return HTTP.make(path, 'get').data(getFormattedQuery(data));
      },
      download: (data) => {
        return HTTP.make(`${path}/download`, 'get').data(
          getFormattedQuery(data),
        );
      },
      widgets: () => {
        return HTTP.make(`${path}/widgets`, 'get');
      },
    };
  }

  shortestTransitTime(period) {
    const path = `${this.baseUrl}/transit_times/fixed`;

    return HTTP.make(path, 'get').data({ period });
  }

  transitTime({
    period,
    tradeLanes,
    voyages,
    loadingPorts,
    dischargePorts,
    page,
    perPage,
  }) {
    const path = `${this.baseUrl}/transit_times`;
    const query = removeNullProperties({
      trade: getIdsFromArray(tradeLanes),
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      voyage: getIdsFromArray(voyages),
      loading_port: getIdsFromArray(loadingPorts),
      discharge_port: getIdsFromArray(dischargePorts),
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  emissions() {
    const path = `${this.baseUrl}/emissions`;

    return {
      get: ({ period, tradeLanes, voyages, commodities, page, perPage }) => {
        const query = removeNullProperties({
          period: period.id,
          trade: getIdsFromArray(tradeLanes),
          voyage: getIdsFromArray(voyages),
          commodity: getIdsFromArray(commodities),
          per_page: perPage,
          page,
        });

        return HTTP.make(path, 'get').data(query);
      },
      download: ({ voyage_id }) => {
        const query = {
          voyage_id,
        };

        return HTTP.make(`${path}/download`, 'get').data(query);
      },
    };
  }

  claims({
    period,
    tradeLanes,
    voyages,
    loadingPorts,
    dischargePorts,
    commodities,
    page,
    perPage,
  }) {
    const path = `${this.baseUrl}/claims`;
    const query = removeNullProperties({
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      trade: getIdsFromArray(tradeLanes),
      voyage: getIdsFromArray(voyages),
      commodity: getIdsFromArray(commodities),
      loading_port: getIdsFromArray(loadingPorts),
      discharge_port: getIdsFromArray(dischargePorts),
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  bunkers({ type, period, bunkersPorts }) {
    const path = `${this.baseUrl}/bunkers`;
    const query = removeNullProperties({
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      type,
      port: getIdsFromArray(bunkersPorts),
    });

    return HTTP.make(path, 'get').data(query);
  }

  bunkersFilters() {
    const path = `${this.baseUrl}/bunkers/filters`;
    return HTTP.make(path, 'get');
  }

  productivityWidgets() {
    const path = `${this.baseUrl}/productivity/widgets`;
    return HTTP.make(path, 'get');
  }

  laycanFilters() {
    const path = `${this.baseUrl}/shipment_laycan/filters`;
    return HTTP.make(path, 'get');
  }

  productivityFilters() {
    const path = `${this.baseUrl}/productivity/filters`;
    return HTTP.make(path, 'get');
  }

  productivity({ reasonForCall, period, productivityPorts }) {
    const path = `${this.baseUrl}/productivity`;
    const query = removeNullProperties({
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      reason_for_call: reasonForCall?.id,
      port: getIdsFromArray(productivityPorts),
    });

    return HTTP.make(path, 'get').data(query);
  }

  invoiceFilters(type) {
    const path = `${this.baseUrl}/invoices/${type}/filters`;
    return HTTP.make(path, 'get');
  }

  invoices(
    {
      tradeLanes,
      statuses,
      loadingPorts,
      companies,
      voyages,
      period,
      page,
      perPage,
    },
    type,
  ) {
    const path = `${this.baseUrl}/invoices/${type}`;

    const query = removeNullProperties({
      trade: getIdsFromArray(tradeLanes),
      company: getIdsFromArray(companies),
      status: getIdsFromArray(statuses),
      loading_port: getIdsFromArray(loadingPorts),
      voyage: getIdsFromArray(voyages),
      period: period.id,
      cp_start: period.cp_start,
      cp_end: period.cp_end,
      per_page: perPage,
      page,
    });

    return HTTP.make(path, 'get').data(query);
  }

  laytime() {
    const path = `${this.baseUrl}/laytime`;

    return {
      get: ({ period, tradeLanes, laytimeStatuses }) => {
        return HTTP.make(path, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            tradelanes: getIdsFromArray(tradeLanes),
            statuses: getIdsFromArray(laytimeStatuses),
          }),
        );
      },
      show: (id) => HTTP.make(`${path}/${id}`, 'get'),
      filters: () => HTTP.make(`${path}/filters`, 'get'),
      table: ({ period, tradeLanes, laytimeStatuses, perPage, page }, search) =>
        HTTP.make(`${path}/table`, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            tradelanes: getIdsFromArray(tradeLanes),
            statuses: getIdsFromArray(laytimeStatuses),
            per_page: perPage,
            page,
            search,
          }),
        ),
    };
  }

  waitingForBerth() {
    const path = `${this.baseUrl}/waiting_for_berth`;

    return {
      get: ({
        period,
        tradeLanes,
        reasonForCall,
        waitingForBerthPorts,
        page,
        perPage,
      }) => {
        return HTTP.make(path, 'get').data(
          removeNullProperties({
            period: period.id,
            cp_start: period.cp_start,
            cp_end: period.cp_end,
            reason_for_call: reasonForCall?.id,
            ports: getIdsFromArray(waitingForBerthPorts),
            tradelanes: getIdsFromArray(tradeLanes),
            per_page: perPage,
            page,
          }),
        );
      },
      filters: () => HTTP.make(`${path}/filters`, 'get'),
    };
  }
}
