import { nextTick } from 'vue';
import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  hide,
  offset,
  shift,
} from '@floating-ui/dom';

type TooltipDirectiveEl = HTMLElement & {
  _showTooltip?: () => void;
  _hideTooltip?: () => void;
  _tooltipEl?: HTMLDivElement;
  _cleanup: (() => void) | null;
};

const createTooltipEl = (el: TooltipDirectiveEl) => {
  const tooltipEl = document.createElement('div');
  tooltipEl.id = 'tooltip';
  tooltipEl.className =
    'z-[100] w-max max-w-sm rounded bg-charcoal-2 p-4 text-sm text-charcoal-9 absolute top-0 left-0';
  tooltipEl.innerText = el.textContent!;

  return tooltipEl;
};

const createArrowEl = () => {
  const arrowEl = document.createElement('div');
  arrowEl.id = 'arrow';
  arrowEl.className = 'absolute';

  return arrowEl;
};

const Title = {
  mounted(el: TooltipDirectiveEl) {
    nextTick(() => {
      const hasOverflow =
        el.offsetWidth < el.scrollWidth || el.offsetHeight < el.scrollHeight;

      if (!hasOverflow || !el.textContent) {
        return;
      }

      const tooltipTitleEl = document.getElementById('tooltipTitle')!;

      const tooltipEl = createTooltipEl(el);

      const arrowEl = createArrowEl();
      tooltipEl.appendChild(arrowEl);

      const showTooltip = async () => {
        tooltipTitleEl.appendChild(tooltipEl);

        el._cleanup = autoUpdate(el, tooltipEl, async () => {
          const { x, y, placement, middlewareData } = await computePosition(
            el,
            tooltipEl,
            {
              placement: 'bottom-end',
              middleware: [
                offset(8),
                flip(),
                shift({ rootBoundary: 'viewport' }),
                arrow({ element: arrowEl, padding: 10 }),
                hide(),
              ],
            },
          );

          Object.assign(tooltipEl.style, {
            left: `${x}px`,
            top: `${y}px`,
            visibility: middlewareData.hide?.referenceHidden
              ? 'hidden'
              : 'visible',
          });

          if (middlewareData.arrow) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;
            Object.assign(arrowEl.style, {
              left: arrowX != null ? `${arrowX}px` : '',
              top: arrowY != null ? `${arrowY}px` : '',
            });
          }
          tooltipEl.setAttribute('data-floating-placement', placement);
        });
      };

      const hideTooltip = () => {
        if (tooltipEl) {
          tooltipEl.remove();
        }
        if (el._cleanup) {
          el._cleanup();
          el._cleanup = null;
        }
      };

      el.addEventListener('mouseenter', showTooltip);
      el.addEventListener('mouseleave', hideTooltip);

      el._tooltipEl = tooltipEl;
      el._showTooltip = showTooltip;
      el._hideTooltip = hideTooltip;
    });
  },

  unmounted(el) {
    if (el._tooltipEl) {
      el._tooltipEl.remove();
    }
    if (el._cleanup) {
      el._cleanup();
    }
    el.removeEventListener('mouseenter', el._showTooltip);
    el.removeEventListener('mouseleave', el._hideTooltip);
  },
};

export default Title;
