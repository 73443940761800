export const ORG_STATISTIC_FEATURES = {
  STATISTICS_SHIPMENT_VOLUMES: 'feature.statistics.shipment_volumes.view',
  STATISTICS_LAYCAN: 'feature.statistics.laycan.view',
  STATISTICS_TRANSIT_TIME: 'feature.statistics.transit_times.view',
  STATISTICS_EMISSIONS: 'feature.statistics.emissions.view',
  STATISTICS_INVOICES: 'feature.statistics.invoices.view',
  STATISTICS_BUNKERS: 'feature.statistics.bunkers.view',
  STATISTICS_PRODUCTIVITY: 'feature.statistics.productivity.view',
  STATISTICS_CLAIMS: 'feature.statistics.claims.view',
  STATISTICS_LAYTIME: 'feature.statistics.laytime.view',
  STATISTICS_WAITING_FOR_BERTH: 'feature.statistics.waiting_for_berth.view',
} as const;

export const ORG_SETTINGS = {
  ORGANISATION_TEMPLATE_TYPE: 'organisation_template_type',
};

export const ORG_BOOKINGS_TEMPLATES = {
  CMPC: 'cmpc',
  SUZANO: 'suzano',
  MAIN: 'main',
} as const;

export const ORG_NAV_ITEMS_FEATURES = {
  KPIS: 'feature.kpis.view',
  VESSELS: 'feature.vessels.view',
  SAILING_SCHEDULE: 'feature.sailing_schedule.view',
} as const;

export const ORG_OTHER_FEATURES = {
  SAILING_SCHEDULE_SYNC: 'feature.sailing_schedule.sync',
  CARBON_OFFSETTING_WIDGET: 'feature.carbon_offsetting_widget.view',
} as const;

export const ORG_FEATURES = {
  STATISTICS_ALL: 'statistics.all',
  STATISTICS_AVAILABLE: 'feature.statistics_available',
  BOOKINGS: 'feature.bookings.view',
  ...ORG_STATISTIC_FEATURES,
  ...ORG_NAV_ITEMS_FEATURES,
  ...ORG_OTHER_FEATURES,
} as const;

export const FEATURE_TO_TEXT = {
  [ORG_FEATURES.STATISTICS_ALL]: 'Statistics',
  [ORG_FEATURES.STATISTICS_SHIPMENT_VOLUMES]: 'Shipment Volumes on Statistics',
  [ORG_FEATURES.STATISTICS_LAYCAN]: 'Laycan on Statistics',
  [ORG_FEATURES.STATISTICS_TRANSIT_TIME]: 'Transit Time on Statistics',
  [ORG_FEATURES.STATISTICS_EMISSIONS]: 'Emissions on Statistics',
  [ORG_FEATURES.STATISTICS_INVOICES]: 'Invoices on Statistics',
  [ORG_FEATURES.STATISTICS_BUNKERS]: 'Bunkers on Statistics',
  [ORG_FEATURES.STATISTICS_PRODUCTIVITY]: 'Productivity on Statistics',
  [ORG_FEATURES.STATISTICS_CLAIMS]: 'Claims on Statistics',
  [ORG_FEATURES.STATISTICS_LAYTIME]: 'Laytime on Statistics',
  [ORG_FEATURES.STATISTICS_WAITING_FOR_BERTH]:
    'Waiting for Berth on Statistics',

  [ORG_FEATURES.BOOKINGS]: 'Bookings',
  [ORG_BOOKINGS_TEMPLATES.CMPC]: 'Bookings Template CMPC is',
  [ORG_BOOKINGS_TEMPLATES.SUZANO]: 'Bookings Template Suzano is',
  [ORG_BOOKINGS_TEMPLATES.MAIN]: 'Bookings Template Internal is',
  [ORG_FEATURES.KPIS]: 'KPI',
  [ORG_FEATURES.VESSELS]: 'Vessels',
  [ORG_FEATURES.SAILING_SCHEDULE]: 'Sailing Schedule',

  [ORG_FEATURES.CARBON_OFFSETTING_WIDGET]: 'Carbon Offsetting',
  [ORG_FEATURES.SAILING_SCHEDULE_SYNC]: 'Sailing Schedule Sync',
} as const;

export const DATA_CHOICE = {
  LIVE: 'live',
} as const;

export const DEV_FEATURE = {
  PUBLIC_REGISTER: 'PUBLIC_REGISTER',
  NOTIFICATIONS: 'NOTIFICATIONS',
  STATISTICS_WAITING_FOR_BERTH: 'STATISTICS_WAITING_FOR_BERTH',
  SHIPMENTS_NOTIFICATIONS: 'SHIPMENTS_NOTIFICATIONS',
  EMISSIONS_DOWNLOAD: 'EMISSIONS_DOWNLOAD',
  CARGO_NOMINATION: 'CARGO_NOMINATION',
  CARGO_NOMINATION_NOMINATE: 'CARGO_NOMINATION_NOMINATE',
  CARGO_NOMINATION_BUDGET_OVERVIEW: 'CARGO_NOMINATION_BUDGET_OVERVIEW',
  MANAGE_BOOKING_TEMPLATE: 'MANAGE_BOOKING_TEMPLATE',
  CARGO_BOOKING_OVERVIEW: 'CARGO_BOOKING_OVERVIEW',
  CARBON_OFFSET: 'CARBON_OFFSET',
} as const;
