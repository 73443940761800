import { createMemoryHistory, createRouter } from 'vue-router';
import { defineSetupVue3 } from '@histoire/plugin-vue';
import { createPinia } from 'pinia';

import 'uno.css';
import './src/index.css';
import dragScroll from '~/directives/dragScroll';
import title from '~/directives/title';
import { install as installVeeValidateRules } from '~/modules/useVeeValidations';

// export const setupVue3 = defineSetupVue3(({ _app, _story, _variant }) => {
// install all modules under `modules/**.*.ts */`
// Object.values(import.meta.globEager('./src/modules/*.ts')).map((i) =>
//   i.install?.({ app })
// );
// });

const router = createRouter({
  history: createMemoryHistory(),
  routes: [
    {
      path: '',
      name: 'Home',
      component: {
        template: 'Test',
      },
    },
  ],
});

if (!document.getElementById('tooltipTarget')) {
  const tooltipTarget = document.createElement('div');
  tooltipTarget.id = 'tooltipTarget';
  document.body.appendChild(tooltipTarget);
}

export const setupVue3 = defineSetupVue3(({ app }) => {
  const pinia = createPinia();

  installVeeValidateRules();

  app.use(pinia);
  app.use(router);
  app.directive('title', title);
  app.directive('tooltip', () => {});
  app.directive('drag-scroll', dragScroll);
});
